html{
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#0AA;
  min-height: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
  
/* .dashboard {
  background: url(/img/main/abstract.jpg);
  background-size: 100%;
  background-position: top;
} */

form {
  padding: 20px;
  margin-top: 80px;
  margin-bottom: 80px;
}

form button, form h5{
  margin: 20px 0;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #0AA;
  box-shadow: none;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
  color: #0AA !important;
}

#profile-summary-action {
  display: flex;
  list-style: none;
}

#profile {
  background-color: white;
  min-height: 1000px;
  margin-top: 20px;
}

.user-section-negative-margin {
  position: relative;
  margin-top: -115px;
}

#profile-header {
  width: -webkit-fill-available;
  max-height: 300px;
}

#profile-oval {
  padding: 10px;
}