#landing {
    background: linear-gradient(to bottom, rgba(0,0,0,0.66) 100%, transparent), url(/img/main/abstract.jpg);
      background-size: cover, cover;
      background-position: center, center;
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
    align-items: center;
    color:#fff;
  }
  
  #landing h1 {
    font-family: 'Roboto';
    font-size: 5rem;
    display: inline-block;
    padding: 1rem;
    font-weight: 400;
    position: relative;
    opacity: 1;
    transform: scale(1);
    transition: transform 1s ease, opacity 2s ease;
  }
  
  
  #landing h1:before,
  h1:after {
      height: 3px;
      width: 100%;
      content: "";
      background: white;
      display: block;
      position: absolute;
      transition: width 0.7s ease;
      transition-delay: 1s;
    }
  
  #landing h1:before {
      top: 0;
      left: 0;
    }
  
  #landing h1:after {
      bottom: 0;
      right: 0;
    }